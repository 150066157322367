import React, { useEffect, useState } from 'react'
import { Typography, Form, Input, Button, message } from 'antd'
import api from '../../api'

export default () => {
  const [form] = Form.useForm()

  const [dataArray, setDataArray] = useState([''])
  const [formInitialValues, setFormInitialValues] = useState({})

  const getData = async () => {
    try {
      const { data } = await api.get('/content')
      if(data.data) {
        let resp = JSON.parse(data.data)
        if(resp.length) {
          setDataArray(resp)
          resp.forEach((item, index) => {
            form.setFieldValue(`cskh-${index}`, item)
          })
        } else {
          // setDataArray([''])
        }
      }
    } catch (err) {}
  }

  const onFinish = async values => {
    try {
      const parsedArray = dataArray.map(item => {
        return item.trim()
      }).filter(item => item !== '')
      await api.put('/content', { content: JSON.stringify(parsedArray) })
      message.success('Thay đổi thành công')
    } catch (err) {}
  }

  const addItemToArray = () => {
    const newArray = [...dataArray]
    newArray.push('')
    setDataArray(newArray)
  }

  const removeItemFromArray = (index) => {
    const newArray = [...dataArray]
    newArray.splice(index, 1)
    setDataArray(newArray)
  }

  const updateValue = (index, value) => {
    const newArray = [...dataArray]
    newArray[index] = value
    setDataArray(newArray)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <Typography.Title level={5}> Chỉnh sửa đường dẫn CSKH </Typography.Title>
      <br />
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 32 }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <div>
          {dataArray.map((item, index) => {
            return (
              <div key={index}>
                <Form.Item
                  style={{ width: 350}}
                  label="CSKH"
                  name={`cskh-${index}`}
                  rules={[{ required: true, message: 'Không thể trống!' }]}
                >
                  <div style={{display: 'flex'}}>
                    <Input value={item} name={`cskh-${index}`} onChange={(e) => updateValue(index, e.target.value)} />
                    {index === dataArray.length - 1 ? (
                      <Button onClick={() => addItemToArray()} style={{marginLeft: '10px'}} type="primary">
                        Thêm mới
                      </Button>
                    ) : ''
                    }
                    {index !== 0 ? (
                      <Button onClick={() => removeItemFromArray(index)} style={{marginLeft: '10px'}} type="primary">
                        Xóa
                      </Button>
                    ) : ''
                    }
                  </div>
                </Form.Item>
              </div>
            )
          })}
          {/*<Form.Item*/}
          {/*  style={{ width: 350 }}*/}
          {/*  label="CSKH"*/}
          {/*  name="cskh"*/}
          {/*  rules={[{ required: true, message: 'Không thể trống!' }]}*/}
          {/*>*/}
          {/*  <Input />*/}
          {/*</Form.Item>*/}
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
